import { FunctionComponent, useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Option } from 'types/options';
import { Routes } from 'types/routes';

import { ProductTypes } from 'models/Product';

import { imageResizer } from 'utils/image';
import { getOptionValue } from 'utils/option';
import { withVariables } from 'utils/string';

import vars from 'theme/styles/vars';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonStyles } from 'components/layout/Button';
import Checkbox, { InputStyles } from 'components/layout/forms/Checkbox';
import HtmlStyledContent from 'components/layout/HtmlStyledContent';
import ProductSelect from 'components/layout/ProductSelect';
import ProductSelectHorizontal from 'components/layout/ProductSelectHorizontal';
import Tooltip from 'components/layout/Tooltip';
import AddToCartButton from 'components/modules/public/products/AddToCartButton';
import BuyNowButton from 'components/modules/public/products/BuyNowButton';

import { Props } from './index';
import StyledComponent from './styles';

const Portal = dynamic(() => import('components/modules/public/layout/modals/Portal'), { ssr: false });
const ProductBoxBuyNowModal = dynamic(() => import('components/modules/public/products/ProductBoxBuyNowModal'), { ssr: false });

const PublicLayoutOfferBoxProduct: FunctionComponent<Props> = ({
    actions,
    state,
    product,
    control,
    input,
    locationId,
    section,
    isBuyNowDisabled,
    isAddToCartDisabled,
    isCheckButtonVisible,
}) => {
    const {
        id,
        locale,
        type,
        defaultPricingMatrix,
        defaultOngoingPromoPricingMatrix,
        finalPricingMatrixOptions,
        ongoingPromo,
        promoPercent,
        pricing,
        productType,
        hasCheckbox,
    } = product;
    if (!locale && !pricing) return null;
    const localeContext = useContext(LocaleContext);
    const translations = localeContext?.translations?.blockSectionProducts;

    const [currentPricingMatrixOption, setCurrentPricingMatrixOption] = useState<Option<number>>(null);
    const [buyNowModalVisible, setBuyNowModalVisible] = useState<boolean>(false);
    const router = useRouter();

    const packagePriceDisplay = productType === ProductTypes.Package && pricing?.priceDisplay;
    const packageOnGoingPromoPriceDisplay = packagePriceDisplay && ongoingPromo && ongoingPromo.pricing.priceDisplay;
    const isSpecialOfferInCart = !!state?.cart?.items?.find((cartItem) => cartItem?.item?.id === id);
    const isSpecialOffer = product?.isSpecialOffer;
    const isProduct = productType === ProductTypes.Product;
    const isLandingPageView = router.pathname === Routes.LandingPages;
    const visibleProductSelect = isProduct && !isSpecialOffer;

    const minPricingOption = finalPricingMatrixOptions?.[0];
    const maxPricingOption = finalPricingMatrixOptions?.[finalPricingMatrixOptions?.length - 1];

    const onCreateCartOffer = async () => {
        return await actions.createCartItem({
            cartId: state?.cart?.id,
            itemId: id,
            itemType: type,
            quantity: getOptionValue(currentPricingMatrixOption) || 1,
            locationId: locationId || null,
        });
    };

    const preparedUrl = withVariables(Routes.PublicProduct, {
        genderZoneSlug: product?.genderZoneSlug,
        slug: locale?.slug,
    });

    const setShallowQuery = (): Promise<any> => {
        if (router.pathname !== Routes.PublicPriceList) return;
        return router.push(router.pathname, `${router.pathname}?section=${section}`, { shallow: true });
    };

    return (
        <StyledComponent
            className="public-layout-offer-box-product"
            overrideStyles={`
                .offer-image {
                    background-image: url(${imageResizer(locale?.image?.imageUrl, { width: 500 })});
                }

                @media all and (max-width: ${vars.mobileM}) {
                    .offer-image {
                        background-image: url(${imageResizer(locale?.image?.imageUrl, { width: 480 })});
                    }
                }
            `}
        >
            {locale?.image && (
                <>
                    {isLandingPageView ? (
                        <div className="image-container">
                            <div className={classnames('offer-image', isProduct ? 'cover' : null)} />
                        </div>
                    ) : (
                        <a
                            href={preparedUrl}
                            onClick={(e) => {
                                e.preventDefault();
                                setShallowQuery();
                                window.location.href = preparedUrl;
                            }}
                        >
                            <div className="image-container">
                                <div className={classnames('offer-image', isProduct ? 'cover' : null)} />
                            </div>
                        </a>
                    )}
                </>
            )}
            <div className="offer-content">
                <div className="offer-content-header">
                    {isLandingPageView ? (
                        <div
                            className="offer-name"
                        >
                            {locale?.name}
                        </div>
                    ) : (
                        <a
                            className="clickable"
                            href={preparedUrl}
                            onClick={(e) => {
                                e.preventDefault();
                                setShallowQuery();
                                window.location.href = preparedUrl;
                            }}
                        >
                            <div
                                className="offer-name"
                            >
                                {locale?.name}
                            </div>
                        </a>
                    )}
                </div>
                <div className="flex-col">
                    {locale?.shortDescription && (
                        <div className="offer-description">
                            <HtmlStyledContent html={locale?.shortDescription} />
                        </div>
                    )}
                    <div className="offer-pricing">
                        <div className="row">
                            <div className="price-data-container">
                                <div className="prices-container">
                                    {(!ongoingPromo || (ongoingPromo && promoPercent <= 0)) && (
                                        <>
                                            <div
                                                className="pricing-price"
                                                dangerouslySetInnerHTML={{ __html: currentPricingMatrixOption?.['priceDisplay'] || packagePriceDisplay || defaultPricingMatrix?.priceDisplay }}
                                            />
                                            {(currentPricingMatrixOption?.data?.regularPriceDisplay && Boolean(currentPricingMatrixOption?.value > 1)) && (
                                                <div
                                                    className="pricing-old-price"
                                                    dangerouslySetInnerHTML={{ __html: currentPricingMatrixOption?.data?.regularPriceDisplay }}
                                                />
                                            )}
                                        </>
                                    )}
                                    {(ongoingPromo && promoPercent > 0) && (
                                        <>
                                            <div
                                                className="pricing-price"
                                                dangerouslySetInnerHTML={{ __html: currentPricingMatrixOption?.['priceDisplay'] || packageOnGoingPromoPriceDisplay || defaultOngoingPromoPricingMatrix?.priceDisplay }}
                                            />
                                            <div
                                                className="pricing-old-price"
                                                dangerouslySetInnerHTML={{ __html: currentPricingMatrixOption?.data?.regularPriceDisplay || packagePriceDisplay || defaultPricingMatrix?.priceDisplay }}
                                            />
                                        </>
                                    )}
                                </div>
                                {(ongoingPromo && promoPercent > 0 && !currentPricingMatrixOption?.data?.promoProcent) && (
                                    <div className="discount">
                                        -{promoPercent}%
                                    </div>
                                )}
                                {currentPricingMatrixOption?.data?.promoProcent > 0 && (
                                    <div className="discount">
                                        -{currentPricingMatrixOption?.data?.promoProcent}%
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="column">
                            {product?.showProductDuration && (
                                <div className="offer-duration-container">
                                    {translations?.product?.productDurationTo}
                                    <span>
                                        {product?.productDurationToDisplay?.day}
                                        {' '}
                                        {translations?.product?.to}
                                        {' '}
                                        {product?.productDurationToDisplay?.hour}
                                    </span>
                                </div>
                            )}
                            {product?.showLowestPrice && (
                                <div className="last-month-price-container">
                                    <div className="info-mark">
                                        i
                                        <div className="info-mark__information">
                                            {translations?.product?.tooltipInfo}
                                        </div>
                                    </div>
                                    {translations?.product?.lowestPrice}
                                    <span>
                                        {product?.displayLowestPriceValue}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    {(hasCheckbox && isProduct && maxPricingOption && visibleProductSelect) && (
                        <div className="checkbox-add-max">
                            <Checkbox
                                name={`checkbox-add-max-${id}`}
                                style={InputStyles.BoldGreen}
                                label={product?.checkboxLabel}
                                onChange={() =>
                                    setCurrentPricingMatrixOption(
                                        currentPricingMatrixOption === maxPricingOption ? minPricingOption : maxPricingOption
                                    )
                                }
                                labelOnClick={() =>
                                    setCurrentPricingMatrixOption(
                                        currentPricingMatrixOption === maxPricingOption ? minPricingOption : maxPricingOption
                                    )
                                }
                                value={Boolean(currentPricingMatrixOption === maxPricingOption)}
                            />
                            <div className="checkbox-add-max-info">
                                <Tooltip
                                    name={`tooltip-info-${id}`}
                                    key={`tooltip-${id}`}
                                    text={translations?.product?.tooltipAddMax}
                                >
                                    <FontAwesomeIcon
                                        width={12}
                                        height={12}
                                        icon={faInfoCircle}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    {(visibleProductSelect) && (
                        <ProductSelect
                            className="visible-on-desktop"
                            options={finalPricingMatrixOptions}
                            onChange={(nextOption: Option<number>) => setCurrentPricingMatrixOption(nextOption)}
                            value={currentPricingMatrixOption}
                            placeholder={input?.quantity?.placeholder}
                            isAddToCartDisabled={isAddToCartDisabled}
                            cartClick={onCreateCartOffer}
                            selectOptions={{ deselect: true, buyNowButton: true }}
                        />
                    )}
                    {(visibleProductSelect) && (
                        <ProductSelectHorizontal
                            className="visible-on-mobile"
                            options={finalPricingMatrixOptions}
                            onChange={(nextOption: Option<number>) => setCurrentPricingMatrixOption(nextOption)}
                            placeholder={input?.quantity?.placeholder}
                            value={currentPricingMatrixOption}
                        />
                    )}
                    <div className={classnames('pricing-controls', {
                        'one-button': !isBuyNowDisabled || !isAddToCartDisabled,
                    })}
                    >
                        {(!isBuyNowDisabled && !isCheckButtonVisible) && (
                            <BuyNowButton
                                label={control?.buyNow?.label || ''}
                                onClick={() => setBuyNowModalVisible(true)}
                            />
                        )}
                        {(!isAddToCartDisabled && !isCheckButtonVisible) && (
                            <AddToCartButton
                                label={control?.addToCart?.label || ''}
                                onClick={onCreateCartOffer}
                                disabled={(isSpecialOfferInCart && isSpecialOffer)}
                            />
                        )}
                        {isCheckButtonVisible && (
                            <Button
                                className="check-button"
                                onClick={() => window.location.href = withVariables(Routes.PublicProduct, {
                                    genderZoneSlug: product?.genderZoneSlug,
                                    slug: locale?.slug,
                                })}
                                style={ButtonStyles.Gold}
                            >
                                Sprawdź
                            </Button>
                        )}
                    </div>
                    {(isProduct && visibleProductSelect) && (
                        <div className="price-info">
                            <p className="price-info__gold">
                                Cena za zabieg:
                                <span className="price-info__bigger">
                                    {currentPricingMatrixOption?.perProcedure || finalPricingMatrixOptions?.[0]?.perProcedure}
                                </span>
                            </p>
                            <p className="price-info__grey">
                                Cena regularna:
                                <span className="price-info__bigger">
                                    {pricing?.priceDisplay}
                                </span>
                            </p>
                        </div>
                    )}

                </div>
            </div>
            {buyNowModalVisible && (
                <Portal>
                    <ProductBoxBuyNowModal
                        product={product}
                        options={finalPricingMatrixOptions}
                        selectedOption={currentPricingMatrixOption}
                        onClose={() => setBuyNowModalVisible(false)}
                    />
                </Portal>
            )}
        </StyledComponent>
    );
};

export default PublicLayoutOfferBoxProduct;
